import { useAuth } from "../../Providers/AuthProvider";
import PaymentUtils from "./PaymentUtils";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useEffect, useState } from "react";
import InputFeildLoader from "./InputFeildLoader";

export default function Payment() {
  const [userPrevData, setUserPrevData] = useState(null);
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  // Fetch user's previous payment data
  useEffect(() => {
    if (currentUser?.email) {
      setLoading(true);
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/user/get-payment-history`, {
          email: currentUser.email,
        })
        .then((response) => {
          setUserPrevData(response.data[0] || null);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching payment history:", error);
        });
    }
  }, [currentUser?.email]);

  const formik = useFormik({
    initialValues: {
      tid: "",
      merchant_id: 3274726,
      order_id: PaymentUtils.generate_order_id(),
      currency: "INR",
      redirect_url: `${process.env.REACT_APP_SERVER_URL}/payments/ccavResponseHandler`,
      cancel_url: `${process.env.REACT_APP_SERVER_URL}/payments/ccavResponseHandler`,
      language: "EN",
      billing_name: userPrevData?.billing_name || "",
      billing_tel: userPrevData?.billing_tel || "",
      billing_email: currentUser?.email || "",
      amount: "",
      billing_course: userPrevData?.billing_course || "",
      billing_payment_type: "",
      termsAccepted: false,
    },
    validationSchema: Yup.object({
      billing_name: Yup.string().required("Full Name is required"),
      billing_tel: Yup.string()
        .matches(/^\d{10}$/, "Please enter a valid 10-digit phone number")
        .required("Phone number is required"),
      billing_email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      amount: Yup.number()
        .typeError("Amount must be a number")
        .min(11, "Amount must be at least 11")
        .required("Amount is required"),
      billing_course: Yup.string().required("Please select a course"),
      billing_payment_type: Yup.string().required(
        "Please select a payment type"
      ),
      termsAccepted: Yup.boolean()
        .oneOf([true], "You must accept the Terms and Conditions")
        .required("Terms acceptance is required"),
    }),
    onSubmit: (values) => {
      document.customerData.submit();
    },
  });

  // Update form values when userPrevData changes
  useEffect(() => {
    formik.setValues((prevValues) => ({
      ...prevValues,
      billing_name: userPrevData?.billing_name || "",
      billing_tel: userPrevData?.billing_tel || "",
      billing_course: userPrevData?.billing_course || "",
    }));
  }, [userPrevData]);

  useEffect(() => {
    if (formik.values.billing_course === "Investment & Stock Market Bootcamp") {
      formik.setFieldValue("amount", "4999");
    }
  }, [formik.values.billing_course]);
  return (
    <div className="pt-10 hilight">
      <section className="fee-form-container">
        <form
          method="post"
          name="customerData"
          action={`${process.env.REACT_APP_SERVER_URL}/payments/ccavRequestHandler`}
          onSubmit={formik.handleSubmit}
        >
          {/* Hidden Fields */}
          {[
            "tid",
            "merchant_id",
            "order_id",
            "currency",
            "redirect_url",
            "cancel_url",
            "language",
          ].map((field) => (
            <input
              type="hidden"
              name={field}
              value={formik.values[field]}
              key={field}
              readOnly
            />
          ))}

          {/* Full Name */}
          <div className="form-group">
            <label htmlFor="billing_name">Full Name:</label>

            <input
              type="text"
              name="billing_name"
              id="billing_name"
              value={
                loading
                  ? "Fetching previous payments.."
                  : formik.values.billing_name
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly={userPrevData ? true : false}
            />
            {formik.touched.billing_name && formik.errors.billing_name && (
              <div className="error-pay">{formik.errors.billing_name}</div>
            )}
          </div>

          {/* Phone Number */}

          <div className="form-group">
            <label htmlFor="billing_tel">Phone No:</label>

            <input
              type="text"
              name="billing_tel"
              id="billing_tel"
              value={
                loading
                  ? "Fetching previous payments.."
                  : formik.values.billing_tel
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly={userPrevData ? true : false}
            />

            {formik.touched.billing_tel && formik.errors.billing_tel && (
              <div className="error-pay">{formik.errors.billing_tel}</div>
            )}
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="billing_email">Email:</label>
            <input
              type="email"
              name="billing_email"
              id="billing_email"
              value={formik.values.billing_email}
              readOnly
            />
          </div>

          {/* Course Selection */}
          <div className="form-group">
            <label htmlFor="billing_course">Choose Your Course:</label>
            <select
              name="billing_course"
              id="billing_course"
              value={formik.values.billing_course}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select a course" />
              <option
                value="DMX"
                label="DMX (Digital Marketing Experiential)"
              />
              <option value="AOI" label="AOI (Art of Influence)" />
              <option
                value="Investment & Stock Market Bootcamp"
                label="Investment & Stock Market Bootcamp"
              />
            </select>
            {formik.touched.billing_course && formik.errors.billing_course && (
              <div className="error-pay">{formik.errors.billing_course}</div>
            )}
          </div>
          {/* Amount */}
          <div className="form-group">
            <label htmlFor="amount">Amount:</label>
            <input
              type="text"
              name="amount"
              id="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.amount && formik.errors.amount && (
              <div className="error-pay">{formik.errors.amount}</div>
            )}
          </div>

          {/* Payment Type */}
          <div className="form-group">
            <label htmlFor="billing_payment_type">Choose Payment Type:</label>
            <select
              name="billing_payment_type"
              id="billing_payment_type"
              value={formik.values.billing_payment_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="" label="Select payment type" />
              <option value="Admission Fee" label="Admission Fee" />
              <option value="1st Installment" label="1st Installment" />
              <option value="2nd Installment" label="2nd Installment" />
              <option value="Full Payment" label="Full Payment" />
            </select>
            {formik.touched.billing_payment_type &&
              formik.errors.billing_payment_type && (
                <div className="error-pay">
                  {formik.errors.billing_payment_type}
                </div>
              )}
          </div>

          {/* Terms and Conditions */}
          <div className="form-group">
            <label htmlFor="termsAccepted" className="checkboxinput">
              <input
                type="checkbox"
                name="termsAccepted"
                id="termsAccepted"
                checked={formik.values.termsAccepted}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              &nbsp;
              <div>
                I confirm that I have read and accept the
                <a
                  className="text-[#f8d00d]"
                  href="https://www.hashproacademy.com/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>
              </div>
            </label>
            {formik.touched.termsAccepted && formik.errors.termsAccepted && (
              <div className="error-pay">{formik.errors.termsAccepted}</div>
            )}
          </div>

          {/* Submit Button */}
          <div className="form-group">
            <button type="submit">Proceed to Payment</button>
          </div>
        </form>
      </section>
    </div>
  );
}
