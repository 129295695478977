import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import InstaLoader from "./InstaLoader";

// Enhanced validation schema
const validationSchema = Yup.object({
  batchTimingAvalibilty: Yup.string()
    .required("Please select your preferable batch timing")
    .oneOf(["Yes", "No"], "Please select a valid option"),
  currentOccupation: Yup.string()
    .required("Please select your current profession")
    .oneOf(
      ["Student", "Job Seeker", "Employee", "Business Owner", "Others"],
      "Please select a valid occupation"
    ),
  financialGoal: Yup.array()
    .min(1, "Select at least one financial goal")
    .of(
      Yup.string().oneOf(
        ["Saving for Education", "Buying a House", "Early Retirement", "Other"],
        "Invalid selection"
      )
    ),
  fullName: Yup.string()
    .required("Please enter your full name")
    .max(100, "Name is too long")
    .matches(/^[a-zA-Z\s]*$/, "Name should only contain letters"),
  phoneNumber: Yup.string()
    .transform((value) => value.replace(/\s+/g, "")) // Remove spaces
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Please enter your contact number"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email")
    .max(254, "Email is too long"),
  rateYourSelf: Yup.string()
    .required("Please rate yourself")
    .oneOf(
      [
        "Beginner (No knowledge)",
        "Intermediate (Medium knowledge)",
        "Advanced (Active Investor)",
      ],
      "Please select a valid option"
    ),
});

const MultiStepAoiform = () => {
  const [step, setStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  // UTM tracking with error handling

  // Form step validation
  const validateStep = async (validateForm, setTouched, currentStep) => {
    const errors = await validateForm();
    const stepFields = {
      1: ["fullName", "phoneNumber", "email", "rateYourSelf"],
      2: ["currentOccupation", "batchTimingAvalibilty", "financialGoal"],
    };

    const currentErrors = Object.keys(errors).filter((key) =>
      stepFields[currentStep].includes(key)
    );

    if (currentErrors.length === 0) {
      return true;
    }

    setTouched(
      stepFields[currentStep].reduce(
        (acc, field) => ({
          ...acc,
          [field]: true,
        }),
        {}
      )
    );
    return false;
  };

  const [utmSource, setUtmSource] = useState("Insta Registrations");
  const [subSource, setSubSource] = useState("Website");

  const getUTMParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const source = getUTMParameter("utm_source");
    if (source) {
      setUtmSource(source);
    }

    const sub = getUTMParameter("sub_source");
    if (sub) {
      setSubSource(sub);
    }
  }, []);

  // Form submission handler

  // Accessibility-enhanced form field
  const FormField = ({
    label,
    name,
    type = "text",
    options = [],
    required = true,
  }) => (
    <div className="form-field">
      <label htmlFor={name}>
        {label}
        {required && (
          <span className="required-star" aria-label="required">
            *
          </span>
        )}
      </label>

      {type === "radio" || type === "checkbox" ? (
        <div
          role="group"
          aria-labelledby={`${name}-group`}
          className="slectinstaocc"
        >
          {options.map((option) => (
            <label key={option}>
              <Field
                type={type}
                name={name}
                value={option}
                className="checkfeildinsta"
                aria-label={option}
              />
              {option}
            </label>
          ))}
        </div>
      ) : (
        <Field
          type={type}
          name={name}
          id={name}
          className="field"
          aria-label={label}
        />
      )}

      <ErrorMessage
        name={name}
        component="div"
        className="error"
        role="alert"
      />
    </div>
  );

  return (
    <div className="multistepform-container" role="main">
      <div className="instaRightform" id="financebg">
        <h3>Investment & Stock Market Bootcamp</h3>

        <p>
          💡1st ever finance bootcamp launched by Hashpro Academy to equip
          participants with comprehensive investment and stock market knowledge
          to achieve early financial freedom.🚀
        </p>
      </div>

      <Formik
        initialValues={{
          fullName: "",
          phoneNumber: "",
          email: "",
          rateYourSelf: "",
          currentOccupation: "",
          batchTimingAvalibilty: "",
          financialGoal: [],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmissionStatus("");
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}/instaForm/finance`, {
              ...values,
              source: utmSource,
              sub_source: subSource,
            })
            .then((response) => {
              setSubmissionStatus("success");
              navigate("/instaFinancethankyou");
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setSubmissionStatus("failed");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, validateForm, setTouched }) => (
          <Form className="instaform" noValidate>
            {/* Step 1 */}
            {step === 1 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 0%, #807e7e 0%) 1",
                }}
              >
                <FormField label="Full Name" name="fullName" type="text" />

                <FormField label="Phone Number" name="phoneNumber" type="tel" />

                <FormField label="Email" name="email" type="email" />

                <FormField
                  label="Rate yourself"
                  name="rateYourSelf"
                  type="radio"
                  options={[
                    "Beginner (No knowledge)",
                    "Intermediate (Medium knowledge)",
                    "Advanced (Active Investor)",
                  ]}
                />

                <div className="singlenextinstabtn">
                  <button
                    type="button"
                    onClick={async () => {
                      if (await validateStep(validateForm, setTouched, 1)) {
                        setStep(2);
                      }
                    }}
                    disabled={isSubmitting}
                    aria-label="Next step"
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {/* Step 2 */}
            {step === 2 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 50%, #807e7e 50%) 1",
                }}
              >
                <FormField
                  label="Your current status"
                  name="currentOccupation"
                  type="radio"
                  options={[
                    "Student",
                    "Job Seeker",
                    "Employee",
                    "Business Owner",
                    "Others",
                  ]}
                />

                <FormField
                  label="Are you available during the bootcamp's scheduled time (10 AM - 1 PM)?"
                  name="batchTimingAvalibilty"
                  type="radio"
                  options={["Yes", "No"]}
                  className="flex"
                />

                <FormField
                  label="What are your current financial goals?"
                  name="financialGoal"
                  type="checkbox"
                  options={[
                    "Saving for Education",
                    "Buying a House",
                    "Early Retirement",
                    "Other",
                  ]}
                />

                <div className="btninstagroup">
                  <button
                    type="button"
                    onClick={() => setStep(1)}
                    disabled={isSubmitting}
                    aria-label="Previous step"
                  >
                    <FiArrowLeft aria-hidden="true" /> Previous
                  </button>

                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <InstaLoader />
                    ) : submissionStatus === "failed" ? (
                      "Failed to Submit"
                    ) : submissionStatus === "success" ? (
                      "Submitted Form"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepAoiform;
