import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import InstaLoader from "./InstaLoader";

const validationSchema = Yup.object({
  offlineConfirmation: Yup.string().required("Please select yes or no"),
  durationConfirmation: Yup.string().required("Please select yes or no"),
  batchTiming: Yup.string().required(
    "Please select your preferable batch timing"
  ),
  currentOccupation: Yup.array().min(1, "Select at least one occupation"),
  fullName: Yup.string().required("Please enter your full name"),
  phoneNumber: Yup.string()

    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Please enter your contact number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),
  streetAddress: Yup.string().required("Please enter your street address"),
  whyAOI: Yup.string()
    .required("Please specify what triggered your interest")
    .when("step", {
      is: 4,
      then: Yup.string().required(
        "Please specify what triggered your interest"
      ),
    }),
});

const MultiStepAoiform = () => {
  const [step, setStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const navigate = useNavigate();

  const [utmSource, setUtmSource] = useState("Insta Registrations");
  const [subSource, setSubSource] = useState("Website");

  const getUTMParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const source = getUTMParameter("utm_source");
    if (source) {
      setUtmSource(source);
    }

    const sub = getUTMParameter("sub_source");
    if (sub) {
      setSubSource(sub);
    }
  }, []);

  const nextStep = async (validateForm, setTouched) => {
    const errors = await validateForm();
    const relevantFields = {
      1: ["fullName", "phoneNumber", "email", "streetAddress"],
      2: ["offlineConfirmation", "durationConfirmation", "batchTiming"],
      3: ["currentOccupation"],
      4: ["whyAOI"],
    };

    const currentErrors = relevantFields[step].reduce((acc, field) => {
      if (errors[field]) {
        acc[field] = errors[field];
      }
      return acc;
    }, {});

    if (Object.keys(currentErrors).length === 0) {
      setStep(step + 1);
    } else {
      setTouched(
        relevantFields[step].reduce((acc, field) => {
          acc[field] = true;
          return acc;
        }, {})
      );
    }
  };

  const prevStep = () => setStep(step - 1);

  return (
    <div className="multistepform-container">
      <div className="instaRightform" id="aoibg">
        <h3>Art of Influence</h3>

        <p>
          A course for every aspiring individual with a blend of business and
          personal skills. In this growing world of AI, people need to be
          nurtured not on the ever-changing technical skills but on the
          personality skills that enhance their technical abilities.
        </p>
      </div>
      <Formik
        initialValues={{
          offlineConfirmation: "",
          durationConfirmation: "",
          batchTiming: "",
          currentOccupation: [],
          fullName: "",
          phoneNumber: "",
          email: "",
          streetAddress: "",
          whyAOI: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmissionStatus("");
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}/instaForm/aoi`, {
              ...values,
              source: utmSource,
              sub_source: subSource,
            })
            .then((response) => {
              console.log("Data submitted:", response);
              setSubmissionStatus("success");
              navigate("/instaAOIthankyou");
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setSubmissionStatus("failed");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, validateForm, setTouched }) => (
          <Form className="instaform">
            {step === 1 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 0%, #807e7e 0%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    Full Name <span className="required-star">*</span>
                  </label>
                  <Field type="text" name="fullName" className="field" />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-field">
                  <label>
                    Phone Number <span className="required-star">*</span>
                  </label>
                  <Field type="text" name="phoneNumber" className="field" />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-field">
                  <label>
                    Email <span className="required-star">*</span>
                  </label>
                  <Field type="email" name="email" className="field" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-field">
                  <label>
                    Street Address <span className="required-star">*</span>
                  </label>
                  <Field type="text" name="streetAddress" className="field" />
                  <ErrorMessage
                    name="streetAddress"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="singlenextinstabtn">
                  <button
                    type="button"
                    onClick={() => nextStep(validateForm, setTouched)}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 25%, #807e7e 25%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    Are you interested in this offline program in Himayat Nagar,
                    Hyderabad?<span className="required-star">*</span>
                  </label>
                  <div className="readiofeild">
                    <div>
                      <Field
                        type="radio"
                        name="offlineConfirmation"
                        value="Yes"
                        className="readi-btn"
                      />
                      <label className="readibtn-label">Yes</label>
                    </div>
                    <div>
                      <Field
                        type="radio"
                        name="offlineConfirmation"
                        value="No"
                        className="readi-btn"
                      />
                      <label className="readibtn-label">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="offlineConfirmation"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-field" style={{ marginTop: "20px" }}>
                  <label>
                    Are you ready for 60+ days of 3 hours per day?
                    <span className="required-star">*</span>
                  </label>
                  <div className="readiofeild">
                    <div>
                      <Field
                        type="radio"
                        name="durationConfirmation"
                        value="Yes"
                        className="readi-btn"
                      />
                      <label className="readibtn-label">Yes</label>
                    </div>
                    <div>
                      <Field
                        type="radio"
                        name="durationConfirmation"
                        value="No"
                        className="readi-btn"
                      />
                      <label className="readibtn-label">No</label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="durationConfirmation"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-field" style={{ marginTop: "20px" }}>
                  <label>
                    Preferred batch timing
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="batchTiming"
                    className="field custom-select"
                  >
                    <option value="">
                      Select Batch Timing{" "}
                      <span className="required-star">*</span>
                    </option>
                    <option value="10 AM to 1 PM">10 AM to 1 PM</option>
                    <option value="6 PM to 9 PM">6 PM to 9 PM</option>
                  </Field>
                  <ErrorMessage
                    name="batchTiming"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" />
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => nextStep(validateForm, setTouched)}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 50%, #807e7e 50%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    What is your current occupation?
                    <span className="required-star">*</span>
                  </label>
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    className="slectinstaocc"
                  >
                    <label>
                      <Field
                        type="checkbox"
                        name="currentOccupation"
                        value="Student"
                        className="checkfeildinsta"
                      />
                      Student
                    </label>
                    <label>
                      <Field
                        type="checkbox"
                        name="currentOccupation"
                        value="Working Professional"
                        className="checkfeildinsta"
                      />
                      Working Professional
                    </label>
                    <label>
                      <Field
                        type="checkbox"
                        name="currentOccupation"
                        value="Business Owner"
                        className="checkfeildinsta"
                      />
                      Business Owner
                    </label>
                    <label>
                      <Field
                        type="checkbox"
                        name="currentOccupation"
                        value="Have a startup idea"
                        className="checkfeildinsta"
                      />
                      Have a startup idea
                    </label>
                  </div>
                  <ErrorMessage
                    name="currentOccupation"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" />
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => nextStep(validateForm, setTouched)}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 4 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 80%, #807e7e 80%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    What triggered your interest in Art of Influence?
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    as="textarea"
                    name="whyAOI"
                    className="field"
                    id="aoitextarea"
                  />
                  <ErrorMessage
                    name="whyAOI"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" />
                    Previous
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <InstaLoader />
                    ) : submissionStatus === "failed" ? (
                      "Failed to Submit"
                    ) : submissionStatus === "success" ? (
                      "Submitted Form"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepAoiform;
